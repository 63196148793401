<template>
  <div class="add-main yt-flex-layout">
    <div class="bread-div yt-flex-layout">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="'/commodity/list'">商品列表</el-breadcrumb-item>
        <el-breadcrumb-item>商品增加</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="add-content">
      <div class="card basic-card yt-flex-layout">
        <div class="card-header yt-flex-layout">基本信息</div>
        <div class="padding-left yt-flex-layout" style="margin-top: 20px">
          <div class="yt-flex-layout" style="width: 50%;margin-right: 20px">
            <span class="red-span">*</span>
            <el-select
              :disabled="Boolean(editorId)"
              v-model="productAddForm.courseId"
              @change="hanldeSelect"
              style="width: 100%"
              placeholder="请选择课程"
            >
              <el-option v-for="(c, index) in courseList" :key="index" :value="c.courseId" :label="c.courseName" />
            </el-select>
          </div>
          <div class="yt-flex-layout" style="width: 50%">
            <span class="red-span">*</span>
            <el-input v-model="productAddForm.name" placeholder="请输入商品名称、默认为课程名称" :maxlength="50" show-word-limit />
          </div>
        </div>
        <div class="padding-left" style="margin: 20px 0;padding-left: 70px">
          <el-input
            resize="none"
            v-model="productAddForm.description"
            :autosize="{ minRows: 1, maxRows: 6 }"
            type="textarea"
            style="height: 40px"
            placeholder="请输入宣传文案"
            :maxlength="200"
            show-word-limit
          />
        </div>
      </div>
      <div class="card course-card yt-flex-layout" v-loading="urlLoading" element-loading-text="图片上传中">
        <div class="card-header yt-flex-layout">课程封面</div>
        <div class="course-body yt-flex-layout">
          <div class="yt-flex-layout" style="align-items: center" v-if="!Boolean(editorId)">
            <el-switch v-model="value" active-color="#13ce66" inactive-color="#EBEBEB" />
            <span style="margin-left: 10px;font-size: 14px;color: #333">直接使用课程封面</span>
          </div>
          <div class="course-upload yt-flex-layout">
            <el-upload v-show="!url" class="upload-demo" drag accept=".png,.jpg,.jpeg,.gif" action="''" multiple :before-upload="handleUpload">
              <!--              <i class="el-icon-upload" />-->
              <JYIcon :href="'#icon-daoruwenjian-gaoliangzhuangtaiicon'" style="width: 48px;height: 38px;margin: 45px 0 12px 0;fill: #CCCCCC" />
              <div class="el-upload__text">
                建议尺寸:120*90px， 或4:3格式，支持jpg、 png格式，图片小于5M
              </div>
            </el-upload>
            <div class="not-url-style" v-show="url" :style="{ backgroundImage: 'url(' + url + ')' }">
              <span @click="url = null" v-show="!value" class="close-style yt-flex-layout flexCenter">X</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card products-card yt-flex-layout">
        <div class="card-header yt-flex-layout">商品信息</div>
        <div class="product-info-content yt-flex-layout">
          <div class="yt-flex-layout charge-style">
            <span class="red-span" style="margin-right: 3px">*</span><span>收费方式:</span>

            <el-radio-group style="margin-left: 20px" v-model="isCharge" @change="handleFreeType">
              <el-radio :label="false"
                >免费
                <el-tooltip placement="top" content="免费指的是这个课程所有的信息都可操作，包括看视频，做练习与课后考试"
                  ><JYIcon :href="'#icon-tishi'"/></el-tooltip
              ></el-radio>
              <el-radio :label="true"
                >收费 <el-tooltip placement="top" content="收费的需要指定价格与可以查看的章节，有效期"><JYIcon :href="'#icon-tishi'"/></el-tooltip
              ></el-radio>
            </el-radio-group>
          </div>
          <div class="price-style yt-flex-layout" :style="isCharge ? 'padding-left: 0' : ''">
            <div class="yt-flex-layout" style="margin-right: 20px;width: 50%;align-items: center">
              <span v-show="isCharge" class="red-span" style="margin-right: 3px">*</span>
              <el-input @blur="validateDoller($event, 1)" v-model="productAddForm.price" :disabled="!isCharge" placeholder="请输入原价">
                <div slot="suffix">元</div>
              </el-input>
            </div>
            <el-input
              @blur="validateDoller($event, 2)"
              style="width: 50%;"
              v-model="productAddForm.discountedPrice"
              :disabled="!isCharge"
              placeholder="请输入优惠价"
            >
              <div slot="suffix">元</div>
            </el-input>
          </div>
          <div class="haveA-try-chapter yt-flex-layout" :style="isCharge ? 'padding-left: 0' : ''">
            <span v-show="isCharge" class="red-span" style="margin-right: 3px">*</span>
            <el-select style="width: 100%" multiple v-model="productAddForm.freeChapterIds" :disabled="!isCharge" placeholder="请选择试看章节">
              <el-option v-for="(c, index) in chapterList" :key="index" :value="c.chapterId" :label="c.chapterName" />
            </el-select>
          </div>
          <div class="yt-flex-layout charge-style">
            <span v-show="isCharge" class="red-span" style="margin-right: 3px">*</span>
            <el-select
              :style="isCharge ? 'padding-left: 0' : ''"
              :disabled="!isCharge"
              style="width: 50%;padding-left: 8px"
              @change="handleChangeDays"
              v-model="productAddForm.timeLimit"
              placeholder="请选择有效期"
            >
              <el-option v-for="(l, index) in limitDays" :key="index" :value="l" :label="index !== 3 ? `${l}天` : '自定义'" />
            </el-select>
            <el-input
              v-show="productAddForm.timeLimit === 3"
              style="width: 50%;margin-left: 20px"
              v-model="customizeDay"
              placeholder="请输入自定义天数"
              @blur="validateD"
            >
              <div slot="suffix">天</div>
            </el-input>
          </div>
        </div>
      </div>
      <div class="footer-btn yt-flex-layout flexCenter">
        <el-button v-if="!Boolean(editorId)" @click="release(1)" size="small" :loading="loading" class="btn" type="primary">发布</el-button>
        <el-tooltip :content="!Boolean(editorId) ? '保存但不发布' : '保存修改'" effect="light">
          <el-button size="small" @click="!Boolean(editorId) ? release(0) : modifyRelease()" :loading="loading" class="btn" type="primary"
            >保存</el-button
          >
        </el-tooltip>
        <el-button @click="cancel()" size="small" :loading="loading" class="btn">取消</el-button>
      </div>
    </div>
    <ConfirmDialog ref="cancelModal" :title="'警告'" @on-ok="$router.back()">
      <div style="width: 219px; margin: 0 auto;">
        <JYIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        <span style="margin-left: 10px">确定取消吗,此操作会丢失数据</span>
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import fileApi from '@api/file'
import oss from '@api/oss'
import JYIcon from '@components/common/JYIcon'
import productApi from '@api/product'
import ConfirmDialog from '@/components/common/dialog/ConfirmDialog'
export default {
  name: 'CommodityAdd',
  components: { JYIcon, ConfirmDialog },
  data() {
    return {
      productAddForm: {
        feeType: 0, // 收费方式 0 免费
        courseId: null,
        price: null, // 原价
        flowLimit: 0, // 流量限制
        description: null, // 宣传文案
        discountedPrice: null, // 优惠价
        timeLimit: null, // 有效期
        freeChapterIds: null, // 章节
        cover: null, // 封面
        state: 0,
        name: null
      },
      value: false, // 是否使用课程封面作为商品图片
      urlLoading: false, // 图片上传中
      courseList: [],
      validateFile: ['.png', '.jpg', '.jpeg', '.gif'],
      chapterList: [],
      limitDays: [30, 60, 90, 3],
      isCharge: false, // 收费方式 false 免费
      customizeDay: null,
      selectRow: null, // 选择的课程信息
      url: null,
      editorId: null, // 编辑时会有此id null即为创建
      loading: false
    }
  },
  computed: {
    isChange() {
      const { value, selectRow } = this
      return { value, selectRow }
    }
  },
  watch: {
    isChange: {
      handler({ value, selectRow }) {
        if (value && selectRow) {
          this.url = selectRow.cover
        }
        if (!value) {
          this.url = null
        }
      },
      deep: true
    },
    isCharge: {
      handler(val) {
        // 切换收费方式时  需重置价格、优惠价和试看章节
        if (!val) {
          this.productAddForm.freeChapterIds = []
        }
      },
      deep: true
    }
  },
  created() {
    this.getAllCourse()
    this.editorId = this.$route.query['id']
    if (this.editorId) {
      // this.isEditor = true
      this.getInfo()
    }
  },
  methods: {
    handleFreeType() {
      // 选择收费方式时重置数据
      this.productAddForm.price = null
      this.productAddForm.discountedPrice = null
      this.productAddForm.timeLimit = null
      this.customizeDay = null
      // if (!this.isCharge) {
      //   this.productAddForm.timeLimit = null
      // }
    },
    handleChangeDays() {
      // 选择有效期方式时重置数据
      this.customizeDay = null
    },
    getInfo() {
      // 编辑信息
      productApi.commodityInfo(this.editorId).then(res => {
        let bool
        if (res.res && res.res.courseId) {
          bool = this.getAllChapterByCourseId(res.res.courseId)
        }
        Object.keys(this.productAddForm).forEach(p => {
          this.productAddForm[p] = res.res[p]
        })
        let days = [30, 60, 90]
        if (res.res.timeLimit && days.indexOf(res.res.timeLimit) === -1) {
          this.productAddForm.timeLimit = 3
          this.customizeDay = res.res.timeLimit
        }
        this.isCharge = res.res['feeType'] === 1
        this.productAddForm.productCourseId = res.res['id']
        this.url = res.res['cover'] || null
        if (!this.isCharge) {
          // 免费不用展示试看章节
          return (this.productAddForm.freeChapterIds = null)
        }
        bool.then(data => {
          if (data) {
            let vos = res.res.chapterInfoVOList
            this.productAddForm.freeChapterIds = []
            vos.forEach(v => {
              if (v.type === 0) {
                this.productAddForm.freeChapterIds.push(v.chapterId)
              }
            })
          }
        })
      })
    },
    cancel() {
      // 取消时要提示 以防误操作
      this.$refs['cancelModal'].open()
    },
    validateDoller(e, index) {
      // 校验输入的金额是否正确
      let value = e.target.value
      if (isNaN(value)) {
        this.$message.warning('请输入正确的金额')
        index === 1 ? (this.productAddForm.price = null) : (this.productAddForm.discountedPrice = null)
      } else {
        if (Number(value) > 90000) {
          return index === 1
            ? (this.productAddForm.price = Number(90000).toFixed(2))
            : (this.productAddForm.discountedPrice = Number(90000).toFixed(2))
        }
        index === 1 ? (this.productAddForm.price = Number(value).toFixed(2)) : (this.productAddForm.discountedPrice = Number(value).toFixed(2))
      }
    },
    hanldeSelect(val) {
      this.courseList.forEach(c => {
        if (c.courseId === val) {
          this.selectRow = c
          this.productAddForm.name = this.selectRow.courseName
        }
      })
      this.getAllChapterByCourseId(val)
    },
    async getAllChapterByCourseId(courseId) {
      // 通过courseId查询章节数据
      let bool
      if (courseId) {
        await productApi.chapterList(courseId).then(res => {
          this.chapterList = res.res
          bool = true
        })
      }
      return bool
    },
    getAllCourse() {
      // 获取所有课程
      productApi.AllCourseList().then(res => {
        this.courseList = res.res
      })
    },
    async release(index) {
      // 0 商品添加并发布 1 保存不发布
      this.loading = true
      let msg = this.validateRequired()
      if (msg !== true) {
        this.loading = false
        return this.$message.warning(msg)
      }
      this.productAddForm.feeType = this.isCharge ? 1 : 0
      this.productAddForm.cover = this.url
      this.productAddForm['state'] = index
      let saveData = this.productAddForm.timeLimit
      if (this.customizeDay) {
        this.productAddForm.timeLimit = this.customizeDay
      }
      const res = await productApi.commodityAppend(this.productAddForm).catch(() => {
        this.loading = false
      })
      if (res.code !== 0) {
        this.productAddForm.timeLimit = saveData
        this.loading = false
      } else {
        this.finish(res, index)
      }
    },
    async modifyRelease() {
      // 保存修改
      this.loading = true
      let msg = this.validateRequired()
      if (msg !== true) {
        this.loading = false
        return this.$message.warning(msg)
      }
      this.productAddForm.feeType = this.isCharge ? 1 : 0
      this.productAddForm.cover = this.url
      let saveData = this.productAddForm.timeLimit
      if (this.customizeDay) {
        this.productAddForm.timeLimit = this.customizeDay
      }
      const res = await productApi.commodityModify(this.productAddForm).catch(() => {
        this.loading = false
      })
      res.code !== 0 ? (this.productAddForm.timeLimit = saveData) : this.finish(res, 0)
    },
    finish(data, index) {
      this.$message.success(index === 0 ? '保存成功' : '发布成功')
      this.$router.push('/commodity/list')
      this.loading = false
    },
    validateRequired() {
      if (!this.productAddForm.courseId) return '请选择课程'
      if (!this.productAddForm.name) return '请输入商品名称'
      if (this.isCharge) {
        // 收费项目必填项校验
        if (!this.productAddForm.price) return '请设置商品原价'
        if (this.productAddForm.freeChapterIds.length <= 0) return '请设置试看章节'
        if (!this.productAddForm.timeLimit) return '请设置有效期'
      }
      if (this.productAddForm.timeLimit === 3 && !this.customizeDay) return '自定义有效期时,请手动设置时长'
      return true
    },
    validateD(e) {
      // 校验输入的有效期是否合法
      let v = e.target.value
      if (!isNaN(v)) {
        Number(Math.round(v)) > 3650 ? (this.customizeDay = 3650) : (this.customizeDay = Number(Math.round(v)))
      } else {
        this.$message.warning('输入不合法')
        this.customizeDay = null
      }
    },
    handleUpload(file) {
      // 上传之前的操作
      // 区分局域网和远程 window.uploadUrl true => 本地 false => 远程
      let vFile = file.name.substr(file.name.lastIndexOf('.'), file.name.length)
      if (this.validateFile.indexOf(vFile) === -1) return this.$message.warning('上传的文件格式不支持')
      if (file.name.indexOf('.')) this.loading = true
      this.urlLoading = true
      if (window.uploadUrl) {
        let formData = new FormData()
        formData.append('file', file)
        fileApi
          .uploadServeFile(formData)
          .then(res => {
            if (res.code === 0) this.url = res.res
          })
          .finally(() => {
            this.closeLoading()
          })
      } else {
        let _this = this
        oss
          .upType()
          .then(res => {
            if (res.code === 0) {
              oss
                .getQuestionFileToken(file.name)
                .then(data => {
                  let fData = data.res
                  let formData = new FormData()
                  formData.append('key', fData.dir)
                  formData.append('OSSAccessKeyId', fData.accessId)
                  formData.append('policy', fData.policy)
                  formData.append('Signature', fData.signature)
                  formData.append('file', file)
                  fetch(`https://${fData.host}`, {
                    method: 'POST',
                    body: formData
                  })
                    .then(() => {
                      _this.url = `https://${fData.host}/${fData.dir}`
                    })
                    .finally(() => {
                      this.closeLoading()
                    })
                })
                .catch(() => {
                  this.closeLoading()
                })
            }
          })
          .catch(() => {
            this.closeLoading()
          })
      }
    },
    closeLoading() {
      // 取消所有加载
      this.loading = false
      this.urlLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
.add-main {
  flex-direction: column;
  background-color: #f8f8f8;
  .bread-div {
    align-items: center;
    padding: 0 12px;
    height: 44px;
    background-color: #fff;
    margin-bottom: 20px;
  }
  .add-content {
    padding: 0 20px;
    .card {
      flex-direction: column;
      width: 100%;
      border-radius: 6px;
      background-color: #fff;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    .basic-card {
      .padding-left {
        padding: 0 60px;
        div {
          span {
            margin-right: 5px;
          }
          align-items: center;
        }
        ::v-deep .el-textarea__inner {
          min-height: 40px !important;
          padding-right: 60px !important;
        }
      }
    }
    .course-card {
      .course-body {
        padding: 20px 69px;
        flex-direction: column;
      }
      .course-upload {
        margin-top: 20px;
        .el-upload__text {
          text-align: left;
          .font(12px, 500, #bfc1c6);
          margin: 0 auto;
          width: 122px;
          white-space: normal;
          word-break: break-all;
        }
        ::v-deep {
          .el-upload-dragger {
            height: 200px;
          }
        }
        .not-url-style {
          position: relative;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          .wh(200px, 150px);
          border-radius: 4px;
          border: 1px solid #d9d9d9;
          .close-style {
            position: absolute;
            top: -8px;
            right: -8px;
            .wh(25px, 25px);
            color: #fff;
            border-radius: 50%;
            background-color: #ff5050;
            cursor: pointer;
          }
        }
      }
    }
    .products-card {
      flex-direction: column;
      .product-info-content {
        flex-direction: column;
        margin: 20px 0;
        padding: 0 60px;
        .charge-style {
          align-items: center;
        }
        .price-style {
          margin: 20px 0;
          padding-left: 8px;
        }
        .haveA-try-chapter {
          align-items: center;
          margin-bottom: 20px;
          padding-left: 8px;
        }
      }
      ::v-deep .el-input__suffix {
        right: 10px;
        display: flex;
        align-items: center;
      }
    }
    .card-header {
      .font(14px, 500, #000);
      padding-left: 20px;
      height: 40px;
      align-items: center;
      border-bottom: 1px solid #e2e4e8;
    }
    .footer-btn {
      height: 45px;
      .btn {
        .wh(80px, 32px);
      }
    }
    .red-span {
      .font(12px, 500, #f43b3b);
    }
  }
}
</style>
